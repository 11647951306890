import { render, staticRenderFns } from "./AgencyProfileVolunteersSection.vue?vue&type=template&id=953d0d22&scoped=true&"
import script from "./AgencyProfileVolunteersSection.vue?vue&type=script&lang=js&"
export * from "./AgencyProfileVolunteersSection.vue?vue&type=script&lang=js&"
import style0 from "./AgencyProfileVolunteersSection.vue?vue&type=style&index=0&id=953d0d22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "953d0d22",
  null
  
)

export default component.exports