<template>
  <div class="d-flex flex-column mx-5" style="flex-grow: 1">
    <div class="d-flex flex-row mt-5 align-center w-100 justify-between">
      <span class="font-20 pl-3 blue-font-color app-medium-font white--text"
        >Fanned Volunteers</span
      >
    </div>
    <div class="d-flex mt-3 w-100 flex-wrap">
      <div
        v-for="fan in fans"
        :key="fan"
        class="ma-3 agency-volunteer-profile-card-section"
      >
        <agency-volunteer-profile-card :_id="fan" />
      </div>
      <div
        v-if="fans.length == 0"
        class="align-center justify-center d-flex mt-3 w-100 h-100 flex-column white--text align-center justify-center"
      >
        <div class="mb-3 mt-15">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No volunteers fanned
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import AgencyVolunteerProfileCard from "./AgencyVolunteerProfileCard.vue";
export default {
  components: { AgencyVolunteerProfileCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      fans: [],
    };
  },
  methods: {
    ...mapActions("agency", {
      getFanned: "getFanned",
    }),
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.getFanned({ _id: this.$route.params.id })
      .then((res) => {
        this.loading = false;
        this.fans = res;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
@media (max-width: 520px) {
  .agency-volunteer-profile-card-section {
    width: 100%;
  }
}
@media (min-width: 521px) {
  .agency-volunteer-profile-card-section {
    width: calc(50% - 30px);
  }
}
</style>
